// import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
// export default {
//   install (Vue) {
//     const connection = new HubConnectionBuilder()
//       .withUrl('https://localhost:7074/synapseHub')
//       .configureLogging(LogLevel.Information)
//       .build()
 
//     connection.start()
//   }
// }


